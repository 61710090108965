import { useEffect } from 'react';
import Blog from './blog';
import Home from './indexFull';
import { useRouter } from 'next/router';

type Global = {
  // type definition for global object
};

type Props = {
  global: Global;
};

const App = ({ global }: Props) => {
  const router = useRouter();

  let isFull = router.query.isFull || process.env.NEXT_PUBLIC_FULL_WEBSITE;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Save the isFull value to localStorage
      localStorage.setItem('isFull', isFull.toString());
    }
  }, [isFull]);

  if (isFull === 'true') {
    return <Home global={global} />;
  } else {
    return <Blog global={global} />;
  }
};

export default App;
